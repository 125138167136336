












import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';

@Component({
})
export default class CategoryTitle extends Vue {
  @Prop({default: []})
  private tabBtns!: [];
  @Prop({default: null})
  private queryName!: string;
}
